/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        (function( $ ){
          $.fn.myfunction = function() {

            jQuery(document).ready(function () {

              jQuery('.expanding-grid').expandingGrid();
                   
            });
            
             return this;
          }; 
       })( jQuery );

        jQuery('.home-slider').slick({
          dots: true,
          infinite: true,
          speed: 500,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          cssEase: 'linear',
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a class="slider-dot">0'+(i+1)+'</a>';
            
          }

        });

        if (jQuery(window).width() > 960 ) {

          jQuery('.slider-bg').height(jQuery(window).height());
          }


          jQuery('.member-slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            cssEase: 'linear',
  
          });

          ///filter ///

          jQuery('body').on( 'click','#filter-btn' ,function(e){

            

            jQuery('.expanding-grids').expandingGrids();

            e.preventDefault();

        

          
      //     jQuery('.expanding-grid').removeClass('expanding-grid').addClass('expanding-grids');

       
  
            var pstatus = jQuery('.portfolio-status').val();
            var pcategory = jQuery('.portfolio-category').val();
            var pyear = jQuery('.portfolio-year').val();
  
            jQuery.ajax({
  
              url: ajaxurl,
              type: 'POST', 
              data: {
  
                action: 'cat_filter_ajax_response',
                'pstatus' : pstatus,
                'pcategory' : pcategory,
                'pyear' : pyear,
  
              },
              success: function(data) {
              

                jQuery(document).ready(function () {

                  jQuery('.expanding-grid').expandingGrid();
                       
                });
  


              var $root = jQuery('html, body');
              var position = parseInt( jQuery( jQuery('.holiday-camp-postwrap') ).offset().top )-80;
             
              $root.animate({
  
              scrollTop: position
  
              }, 500);
  
            
   
  
                var data = jQuery(data);
                //data
                jQuery('.expanding-grids').expandingGrids();
  
                jQuery('.ajax-wrap').css({
                  '-webkit-transform' : 'scale(1)',
                  '-moz-transform'    : 'scale(1)',
                  '-ms-transform'     : 'scale(1)',
                  '-o-transform'      : 'scale(1)',
                  'transform'         : 'scale(1)'
                }).html( data );
   
  
              },
              error: function(err) {
  
                console.log(err);
               
  
              }
  
            });
  
          });
            

        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
